<template>
  <div>
    <form
      class="field-width"
      v-on:submit.prevent="$v.$invalid ? null : sendVerificationCode()"
    >
      <div class="step-title">Join heycollab for free</div>
      <div class="step-description">
        By signing up, I agree to the heycollab
        <span
          class="cursor-pointer"
          style="text-decoration: underline;"
          @click="openAgreementDialog('policy')"
          >Privacy Policy</span
        >
        and
        <span
          class="cursor-pointer"
          style="text-decoration: underline;"
          @click="openAgreementDialog('terms')"
          >Terms of Service</span
        >.
      </div>
      <div class="email-input">
        <div class="input-label">Email</div>
        <q-input
          type="text"
          ref="focusname"
          v-model="email"
          @blur="$v.email.$touch()"
          :error="fieldErrors('email').length > 0"
          dense
          outlined
          bottom-slots
          no-error-icon
        >
          <template v-slot:error>
            <div class="text-negative">
              {{
                fieldErrors("email").length > 0 ? fieldErrors("email")[0] : ""
              }}
            </div>
          </template>
        </q-input>
        <q-btn
          type="submit"
          no-caps
          text-color="white"
          padding="0"
          label="Try for free"
          class="signin-btn-class"
          @click="sendVerificationCode()"
          :loading="loader"
          :disabled="$v.$invalid || loader"
          style="width:100%"
        />

      </div>
      <div class="row justify-center items-center member-mobile-view">
        <div class="top-right-text" style="font-weight: 500;color: #000;" >
          Already a member?<span
            class="redirect-text q-ml-xs cursor-pointer"
            @click="$router.push({ name: 'SigninView' })"
            >Sign in</span
          >
        </div>
      </div>
    </form>
    <terms-condition-dialog
      v-model="termsConditionDialog"
      v-if="termsConditionDialog"
      :defaultView="defaultView"
    />
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import TermsConditionDialog from "@/components/Dialogs/TermsConditionDialog";

import { GetVerificationCodeMutation } from "@/gql";
export default {
  name: "Step1",
  components: {
    TermsConditionDialog,
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  validationMessages: {
    email: {
      required: "message.validation.email.required",
      email: "message.validation.email.email",
    },
  },
  mounted() {
    this.$track.page(`virtual/signup/1-email-verification`);
    // this.$nextTick(() => {
    //   this.$refs.focusname.focus();
    // });
  },
  data() {
    return {
      loader: false,
      email: null,
      defaultView: null,
      termsConditionDialog: false,
    };
  },
  methods: {
    openAgreementDialog(view) {
      this.termsConditionDialog = true;
      this.defaultView = view;
    },
    async sendVerificationCode() {
      this.loader = true;
      let variables = { email: this.email };
      try {
        await this.$api.mutate({
          mutation: GetVerificationCodeMutation,
          variables,
        });

        let emitData = {
          email: this.email,
        };

      this.$emit("success", emitData);
      this.$mixpanelEvent('signup-email-1',{email:this.email, distinct_id:'signup-flow'});
      window.gtag('event', 'signup-flow', {
          'event_category': 'signup-email-1',
          'event_label': this.email
      });

      } catch (error) {
        console.log("error", error);
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Internal Server Error",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
      this.loader = false;
    },
  },
};
</script>
