<template>
  <div>
    <form class="field-width" v-on:submit.prevent>
      <div class="step-title">Let’s set up your first project together.</div>
      <div class="join-label-text q-mt-sm">
       What project are you currently working on?
      </div>
      <div>
        <q-input
          type="text"
          ref="focusname"
          v-model="workspace_name"
          @blur="$v.workspace_name.$touch()"
          v-on:keyup="replaceSpace"
          :error="fieldErrors('workspace_name').length > 0"
          dense
          outlined
          bottom-slots
          no-error-icon
        >
          <template v-slot:error>
            <div class="text-negative">
              {{
                fieldErrors("workspace_name").length > 0
                  ? fieldErrors("workspace_name")[0]
                  : ""
              }}
            </div>
          </template>
        </q-input>
        <q-btn
          type="submit"
          no-caps
          text-color="white"
          padding="0"
          label="Next"
          class="signin-btn-class"
          @click="submit()"
          :loading="submitWorkspaceLoader"
          :disabled="disabled"
        />
      </div>
    </form>
    <terms-condition-dialog
      v-model="termsConditionDialog"
      v-if="termsConditionDialog"
      :defaultView="defaultView"
    />
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import TermsConditionDialog from "@/components/Dialogs/TermsConditionDialog";
import { validWorkspaceTitle } from "@/utils/validators";

import { CheckWorkspaceTitleAvaibilityMutation } from "@/gql";
export default {
  name: "Step5",
  props: ["submitWorkspaceLoader"],
  components: {
    TermsConditionDialog,
  },
  mixins: [validationMixin],
  validations: {
    workspace_name: {
      required,
      maxLength: maxLength(32),
      validWorkspaceTitle,
    },
  },
  validationMessages: {
    workspace_name: {
      required: "message.validation.workspace.name.required",
      maxLength: "message.validation.workspace.name.max",
      validWorkspaceTitle: "message.validation.workspace.name.valid",
    },
  },
  mounted() {
    this.$track.page(`virtual/signup/5-setup-project`);
    // this.$nextTick(() => {
    //   this.$refs.focusname.focus();
    // });
  },
  data() {
    return {
      workspace_name: null,
      defaultView: null,
      termsConditionDialog: false,
      loader: {
        checkWorkspace: false,
        submit: false,
      },
      workspaceTaken: false,
    };
  },
  methods: {
    replaceSpace() {
      this.workspace_name = this.workspace_name.replace(/[\W_]/g, "-");
    },
    openAgreementDialog(view) {
      this.termsConditionDialog = true;
      this.defaultView = view;
    },
    async submit() {
      let emitData = {
        workspace_name: this.workspace_name,
      };
      // const result = await this.checWorkspaceAvaibility();
      // if (result) {
      this.$emit("success", emitData);
      // } else {
      //   this.uriSuggestions();
      // }
    },
    async checWorkspaceAvaibility() {
      this.workspaceTaken = false;
      this.loader.checkWorkspace = true;

      let variables = {
        title: this.workspace_name,
      };
      try {
        const response = await this.$api.mutate({
          mutation: CheckWorkspaceTitleAvaibilityMutation,
          variables,
        });

        this.loader.checkWorkspace = false;

        if (response) return response.data.checkWorkspaceTitleAvaibility;
        return false;
      } catch (error) {
        this.loader.checkWorkspace = false;
        return false;
      }
    },
    uriSuggestions() {
      this.workspaceTaken = true;
    },
  },
  computed: {
    disabled() {
      return (
        this.$v.$invalid || this.workspaceTaken || this.loader.checkWorkspace
      );
    },
  },
};
</script>
