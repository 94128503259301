<template>
  <div>
    <form
      class="field-width details-field-width q-mt-md"
      v-on:submit.prevent="$v.$invalid ? null : submit()"
    >
      <div class="step-title" style="padding-bottom:15px;">You’re in! Let's start with the basics.</div>
      <div class="row flex-wrap">
        <div class="spacing-right col-grow q-mb-sm">
          <div class="input-label" style="padding-bottom:5px;">First Name</div>
          <q-input
            type="text"
            ref="focusname"
            v-model="first"
            @input="$v.first.$touch()"
            @blur="$v.first.$touch()"
            :error="fieldErrors('first').length > 0"
            dense
            outlined
            bottom-slots
            no-error-icon
          >
            <template v-slot:error>
              <div class="text-negative">
                {{
                  fieldErrors("first").length > 0 ? fieldErrors("first")[0] : ""
                }}
              </div>
            </template>
          </q-input>
        </div>
        <div class="col-grow q-mb-sm">
          <div class="input-label" style="padding-bottom:5px;">Last Name</div>
          <q-input
            type="text"
            v-model="last"
            @input="$v.last.$touch()"
            @blur="$v.last.$touch()"
            :error="fieldErrors('last').length > 0"
            outlined
            dense
            bottom-slots
            no-error-icon
          >
            <template v-slot:error>
              <div class="text-negative">
                {{
                  fieldErrors("last").length > 0 ? fieldErrors("last")[0] : ""
                }}
              </div>
            </template>
          </q-input>
        </div>
      </div>
      <div class="input-info-btn-popover q-mb-sm">
        <div class="input-label" style="padding-bottom:5px;">Username</div>
        <q-input
          type="text"
          v-model="username"
          @input="handleUsernameInput"
          @blur="$v.username.$touch()"
          :error="fieldErrors('username').length > 0"
          dense
          outlined
          bottom-slots
          no-error-icon
          :hint="
            username && !fieldErrors('username').length
              ? 'Use only lowercase, no spaces, letters, numbers, hyphens & underscores'
              : ''
          "
        >
          <template v-slot:error>
            <div class="text" style="color: #5E7191;font-size: 12px;" v-if="!username">
              Use only lowercase, no spaces, letters, numbers, hyphens &
              underscores
            </div>
            <div class="text-negative" v-else>
              {{
                fieldErrors("username").length > 0
                  ? fieldErrors("username")[0]
                  : ""
              }}
            </div>
          </template>
        </q-input>
        <!-- <v-popover
          autoHide
          :handleResize="true"
          offset="10px"
          class="no-height"
          placement="left-start"
          :open="popover.username"
          popoverClass="vb-info-popover"
        >
          <q-btn
            flat
            size="md"
            round
            :icon="$icons.matInfo"
            class="q-mt-sm"
            style="color:#D2E1F1"
            @mouseenter="popover.username = true"
            @mouseleave="popover.username = false"
          />
          <template slot="popover">
            <div class="vb-popover-information">
              <q-icon
                :name="$icons.matInfo"
                color="white"
                size="md"
                class="absolute"
              />
              <div class="info-container">
                <p>Usernames must be all lowercase, with no spaces.</p>
                <p>
                  They can only contain
                  <span class="demi-bold-pink">
                    letters, numbers, periods,
                    <br />hyphens, and underscores.
                  </span>
                </p>
              </div>
            </div>
          </template>
        </v-popover> -->
      </div>
      <div class="row q-mt-md">
        <div class="spacing-right col-grow input-info-btn-popover q-mb-sm">
          <div class="input-label" style="padding-bottom:5px;">Password</div>
          <q-input
            ref="input"
            type="password"
            v-model="password"
            v-bind:value="password"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            :error="fieldErrors('password').length > 0"
            outlined
            dense
            bottom-slots
            no-error-icon
            :hint="
              password && !fieldErrors('password').length
                ? 'Password must be 8 characters or longer.'
                : ''
            "
          >
            <template v-slot:error>
              <div class="text" style="color: #5E7191;" v-if="!password">
                Password must be 8 characters or longer.
              </div>
              <div class="text-negative" v-else>
                {{
                  fieldErrors("password").length > 0
                    ? fieldErrors("password")[0]
                    : ""
                }}
              </div>
            </template>
          </q-input>
          <!-- <v-popover
            autoHide
            :handleResize="true"
            offset="10px"
            class="no-height"
            placement="bottom-start"
            :open="popover.password"
            popoverClass="vb-info-popover"
          >
            <q-btn
              flat
              size="md"
              round
              style="color:#D2E1F1"
              :icon="$icons.matInfo"
              class="q-mt-sm"
              @mouseenter="popover.password = true"
              @mouseleave="popover.password = false"
            />
            <template slot="popover">
              <div class="vb-popover-information">
                <q-icon
                  :name="$icons.matInfo"
                  color="white"
                  size="md"
                  class="absolute"
                />
                <div class="info-container">
                  <p>
                    Password must be at least 8 characters
                    <br />
                    <span class="demi-bold-pink">Recommended to include:</span>
                  </p>
                  <ul class="password-ul">
                    <li class="demi-bold password-strength-li">
                      1+ uppercase letters
                      <span class="demi-bold-pink">(A, B, C)</span>
                    </li>
                    <li class="demi-bold password-strength-li">
                      1+ special characters
                      <span class="demi-bold-pink">(&,!,@)</span>
                    </li>
                    <li class="demi-bold password-strength-li">
                      1+ numeric characters
                      <span class="demi-bold-pink">(1,2,3)</span>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </v-popover> -->
        </div>
        <div class="col-grow input-info-btn-popover">
          <div class="input-label" style="padding-bottom:5px;">Confirm Password</div>
          <q-input
            type="password"
            v-model="repeatPassword"
            @input="$v.repeatPassword.$touch()"
            @blur="$v.repeatPassword.$touch()"
            :error="fieldErrors('repeatPassword').length > 0"
            outlined
            dense
            bottom-slots
            no-error-icon
          >
            <template v-slot:error>
              <div class="text-negative">
                {{
                  fieldErrors("repeatPassword").length > 0
                    ? fieldErrors("repeatPassword")[0]
                    : ""
                }}
              </div>
            </template>
          </q-input>
          <!-- <v-popover
            autoHide
            :handleResize="true"
            offset="10px"
            class="no-height"
            placement="bottom-start"
            :open="popover.confPassword"
            popoverClass="vb-info-popover"
          >
            <q-btn
              flat
              size="md"
              round
              style="color:#D2E1F1"
              :icon="$icons.matInfo"
              class="q-mt-sm"
              @mouseenter="popover.confPassword = true"
              @mouseleave="popover.confPassword = false"
            />
            <template slot="popover">
              <div class="vb-popover-information">
                <q-icon
                  :name="$icons.matInfo"
                  color="white"
                  size="md"
                  class="absolute"
                />
                <div class="info-container">
                  <p>
                    Password must be at least 8 characters
                    <br />
                    <span class="demi-bold-pink">Recommended to include:</span>
                  </p>
                  <ul class="password-ul">
                    <li class="demi-bold password-strength-li">
                      1+ uppercase letters
                      <span class="demi-bold-pink">(A, B, C)</span>
                    </li>
                    <li class="demi-bold password-strength-li">
                      1+ special characters
                      <span class="demi-bold-pink">(&,!,@)</span>
                    </li>
                    <li class="demi-bold password-strength-li">
                      1+ numeric characters
                      <span class="demi-bold-pink">(1,2,3)</span>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </v-popover> -->
        </div>
      </div>
      <q-btn
        type="submit"
        no-caps
        text-color="white"
        padding="0"
        label="Next"
        class="signin-btn-class"
        @click="submit()"
        :loading="loader"
        :disabled="isDisabled || loader"
      />
    </form>
  </div>
</template>

<script>
// import { VPopover } from "v-tooltip";
import {  uniqueUser } from "@/utils/validators";
import {
  required,
  sameAs,
  minLength,
  maxLength,
  alpha,
} from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
export default {
  name: "Step3",
  mixins: [validationMixin],
  validations: {
    first: {
      required,
      alpha,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    last: {
      required,
      alpha,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    username: {
      required,
      uniqueUser,
      maxLength: maxLength(40),
      minLength: minLength(4),
    },
    password: {
      required,
      // validPassword,
      minLength: minLength(8),
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  validationMessages: {
    first: {
      required: "message.validation.userprofile.first.required",
      alpha: "message.validation.userprofile.first.firstname",
      maxLength: "message.validation.userprofile.first.max",
      minLength: "message.validation.userprofile.first.min",
    },
    last: {
      required: "message.validation.userprofile.last.required",
      alpha: "message.validation.userprofile.last.lastname",
      maxLength: "message.validation.userprofile.last.max",
      minLength: "message.validation.userprofile.last.min",
    },
    username: {
      required: "message.validation.username.required",
      uniqueUser: "message.validation.username.uniqueUser",
      maxLength: "message.validation.username.max",
      minLength: "message.validation.username.min",
    },
    password: {
      required: "message.validation.password.required",
      minLength: "message.validation.password.min",
      validPassword: "message.validation.password.validPassword",
    },
    repeatPassword: {
      sameAsPassword: "message.validation.password.confirm",
    },
  },
  components: {
    // VPopover,
  },
  data() {
    return {
      loader: null,
      first: null,
      last: null,
      username: "",
      password: null,
      repeatPassword: null,
      popover: {
        username: false,
        password: false,
        confPassword: false,
      },
    };
  },
  mounted() {
    this.$track.page(`virtual/signup/3-user-profile`);
  },
  methods: {
    handleUsernameInput() {
      this.$v.username.$touch();
      this.username = this.username.toLowerCase();
    },
    submit() {
      let emitData = {
        first: this.first,
        last: this.last,
        username: this.username,
        password: this.password,
      };
      this.$mixpanelEvent('signup-account-creation-3',{first: this.first,last: this.last,username: this.username, distinct_id:'signup-flow'})
      
      window.gtag('event', 'signup-flow', {
          'event_category': 'signup-account-creation-3',
          'event_label': this.username
      });

      this.$emit("next", emitData);
    },
  },
  computed: {
    isDisabled() {
      if (this.$v.$invalid || this.password.length < 8) {
        return true;
      }
      return false;
    },
  },
};
</script>
