<template>
  <div>
    <form class="field-width" v-on:submit.prevent>
      <div class="step-title q-md">
        Do you want to import tasks & messages?
      </div>
      <div class="join-label-text">You’ll also be able to do this later.</div>
      <div>
        <div class="row import-data">
          <div
            id="trello"
            :class="[
              platform === 'trello' ? 'active-card' : 'inactive-card',
              'row items-center justify-center q-px-md q-py-xs card',
            ]"
            clickable
            @click.stop="selectPlatform('trello')"
          >
            <q-icon
              :name="$icons.mdiTrello"
              :style="
                platform === 'trello'
                  ? { color: '#207ae7' }
                  : { color: '#929398' }
              "
              size="25"
            />
            <div
              style="font-size:20px;align-items:center;font-weight: 600;"
              class="justify-center flex q-ml-xs"
            >
              Trello
            </div>
          </div>
        </div>
        <q-btn
          color="transperant"
          dense
          flat
          outline
          no-caps
          size="15px"
          label="Skip"
          class="q-mr-sm signin-btn-class"
          @click="skipImportData"
          style="border-radius: 7px;border: 1px solid;background: white;"
        />
        <q-btn
          v-if="platform"
          type="submit"
          no-caps
          text-color="white"
          padding="0"
          label="Import from trello"
          class="signin-btn-class"
          @click="authoriseTrelloUser"
          :loading="loader"
        />
      </div>
    </form>
    <terms-condition-dialog
      v-model="termsConditionDialog"
      v-if="termsConditionDialog"
      :defaultView="defaultView"
    />
    <create-trello-workspaceDialog
      v-if="cloneCard.flag"
      v-model="cloneCard.flag"
      :card="cloneCard.card"
      :closeDialog="closeWorkspaceDialog"
      :company="currentCompany"
      :workspace="workspace"
      isFrom="signup"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import TermsConditionDialog from "@/components/Dialogs/TermsConditionDialog";
import CreateTrelloWorkspaceDialog from "@/components/Dialogs/CreateTrelloWorkspaceDialog";
export default {
  name: "Step8",
  props: ["skipImportData", "workspace"],
  components: {
    TermsConditionDialog,
    CreateTrelloWorkspaceDialog,
  },
  mounted() {},
  data() {
    return {
      loader: false,
      email: null,
      email1: null,
      email2: null,
      defaultView: null,
      termsConditionDialog: false,
      verifyCaptch: false,
      captchaToken: null,
      emails: [],
      platform: null,
      cloneCard: {
        flag: false,
        card: null,
      },
    };
  },
  methods: {
    authoriseTrelloUser() {
      this.$trello.authorize({
        type: "popup",
        name: "Heycollab",
        scope: {
          read: true,
          write: true,
        },
        persist: false,
        expiration: "never",
        success: this.authenticationSuccess,
        error: this.authenticationFailure,
      });
    },
    authenticationSuccess() {
      this.getBoards();
    },
    async getBoards() {
      try {
        const memberResponse = await this.$trello.get(
          "tokens/" + this.$trello.token() + "/member"
        );
        if (memberResponse) {
          let boardData = [];
          Promise.all(
            memberResponse.idBoards.map(async (element) => {
              const cardResponse = await this.$trello.get(
                "boards/" + element + "/?lists=all&members=all"
              );
              return cardResponse.closed !== true && cardResponse;
            })
          ).then((boards) => {
            boards.forEach((board) => {
              if (board) {
                board.members = board.members.filter((o) => {
                  return o.id !== memberResponse.id;
                });
                board.lists = board.lists.filter((o) => {
                  return o.closed !== true;
                });
                boardData.push(board);
              }
            });
            this.cloneCard.card = boardData;
            this.cloneCard.flag = true;
            this.cloneCard.loader = false;
          });
        }
      } catch (error) {
        this.cloneCard.loader = false;
      }
    },
    closeWorkspaceDialog() {
      this.$trello.setToken(null);
      this.cloneCard.flag = false;
      this.cloneCard.card = null;
      this.$router.push(
        `/companies/${this.activeCompany}/workspaces/${this.workspace.id}/board`
      );
    },
    authenticationFailure() {
      this.$q.notify({
        color: "negative",
        position: "top-right",
        message: "Authorisation failure!",
        icon: this.$icons.matReportProblem,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
    },
    selectPlatform(name) {
      this.platform = this.platform ? null : name;
    },
    openAgreementDialog(view) {
      this.termsConditionDialog = true;
      this.defaultView = view;
    },
    submit(response) {
      if (response) {
        this.verifyCaptch = true;
        this.captchaToken = response;
      }
    },
  },
  computed: {
    // isDisabled() {
    //   if (this.$v.$invalid) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    isReadyToSend() {
      if (this.email) {
        return true;
      }
      return false;
    },
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.card {
  cursor: pointer;
  height: 62px;
  width: 140px;
  border-radius: 5px;
  .card-text {
    font-size: 25px;
    font-weight: 600;
  }
}
.history-card {
  border: 1px solid #929398;
  background: #e8edf1;
  color: #2b3c58;
}
.inactive-card {
  color: #929398;
  background: #e8edf1;
  border: 1px solid #929398;
}
.active-card {
  color: #2b3c58;
  background: #ffffff;
  cursor: pointer;
  border: 2px solid #006eff;
}
</style>
