<template>
  <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="sitekey" data-size="invisible"></div>
</template>

<script>
export default {
  name: "ReCaptch",
  data() {
    return {
      sitekey: "6LdwD90cAAAAAIeZv76sAVlX_Va7h71k7kPCPezl",
      widgetId: 0,
    };
  },
  methods: {
    execute() {
      window.grecaptcha.execute(this.widgetId);
    },
    reset() {
      window.grecaptcha.reset(this.widgetId);
    },
    callCaptcha() {
      if (window.grecaptcha) {
        this.widgetId = window.grecaptcha.render("g-recaptcha", {
          sitekey: this.sitekey,
          //   size: "invisible",
          // the callback executed when the user solve the recaptcha
          callback: (response) => {
            // emit an event called verify with the response as payload
            this.$emit("verify", response);
            // reset the recaptcha widget so you can execute it again
            this.reset();
          },
        });
      }
    },
  },
  mounted() {
  },
};
</script>
