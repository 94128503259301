<template>
  <div class="company-invite-people">
    <form class="field-width" v-on:submit.prevent>
      <div class="step-title q-md">
        Who’s working on this
      </div>
      <div class="step-title q-mb-md">
        project with you?
      </div>
      <div>
        <q-input
          type="text"
          ref="focusname"
          v-model="email"
          @blur="$v.email.$touch()"
          :error="fieldErrors('email').length > 0"
          dense
          outlined
          bottom-slots
          no-error-icon
          placeholder="Teammate's email"
          style="margin-bottom:16px;"
        >
          <template v-slot:prepend>
            <q-icon
              :name="$icons.fasAt"
              size="18px"
              class="signup-email-icon"
            />
          </template>
          <template v-slot:error>
            <div class="text-negative">
              {{
                fieldErrors("email").length > 0 ? fieldErrors("email")[0] : ""
              }}
            </div>
          </template>
        </q-input>
        <q-input
          type="text"
          v-model="email1"
          @blur="$v.email1.$touch()"
          :error="fieldErrors('email1').length > 0"
          dense
          outlined
          bottom-slots
          no-error-icon
          placeholder="Teammate's email"
          style="margin-bottom:16px;"
        >
          <template v-slot:prepend>
            <q-icon
              :name="$icons.fasAt"
              size="18px"
              class="signup-email-icon"
            />
          </template>
          <template v-slot:error>
            <div class="text-negative">
              {{
                fieldErrors("email1").length > 0 ? fieldErrors("email1")[0] : ""
              }}
            </div>
          </template>
        </q-input>
        <q-input
          type="text"
          v-model="email2"
          @blur="$v.email2.$touch()"
          :error="fieldErrors('email2').length > 0"
          dense
          outlined
          bottom-slots
          no-error-icon
          placeholder="Teammate's email"
          style="margin-bottom:16px;"
        >
          <template v-slot:prepend>
            <q-icon
              :name="$icons.fasAt"
              size="18px"
              class="signup-email-icon"
            />
          </template>
          <template v-slot:error>
            <div class="text-negative">
              {{
                fieldErrors("email2").length > 0 ? fieldErrors("email2")[0] : ""
              }}
            </div>
          </template>
        </q-input>
        <div
          style="margin-top: -20px;"
          v-show="!$v.$invalid && isReadyToSend && !verifyCaptch"
        >
          <re-captch ref="recaptcha" @verify="submit"></re-captch>
        </div>
        <q-btn
          color="transperant"
          dense
          flat
          outline
          no-caps
          size="15px"
          label="Skip"
          class="q-mr-sm signin-btn-class"
          @click="skipInvitation"
          style="border-radius: 7px;border: 1px solid;background: white;"
        />
        <q-btn
          type="submit"
          no-caps
          text-color="white"
          padding="0"
          label="Let's go!"
          class="signin-btn-class"
          @click="sendUserInviation()"
          :loading="loader"
          :disabled="$v.$invalid || !isReadyToSend || !verifyCaptch || loader "
        />
      </div>
    </form>
    <terms-condition-dialog
      v-model="termsConditionDialog"
      v-if="termsConditionDialog"
      :defaultView="defaultView"
    />
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import TermsConditionDialog from "@/components/Dialogs/TermsConditionDialog";
import ReCaptch from "@/components/ReCaptch";
export default {
  name: "Step7",
  props: ["skipInvitation"],
  components: {
    TermsConditionDialog,
    ReCaptch,
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    email1: { email },
    email2: { email },
  },
  validationMessages: {
    email: {
      required: "message.validation.email.required",
      email: "message.validation.email.email",
    },
    email1: {
      email: "message.validation.email.email",
    },
    email2: {
      email: "message.validation.email.email",
    },
  },
  mounted() {
    this.$track.page(`virtual/signup/1-email-verification`);
    // this.$nextTick(() => {
    //   this.$refs.focusname.focus();
    // });
     let that = this;
    window.grecaptcha.ready(function() {
      window.grecaptcha
        .execute("6LdwD90cAAAAAIeZv76sAVlX_Va7h71k7kPCPezl", {
          action: "contact",
        })
        .then(function(token) {
          that.submit(token);
        });
    });
  },
  data() {
    return {
      loader: false,
      email: null,
      email1: null,
      email2: null,
      defaultView: null,
      termsConditionDialog: false,
      verifyCaptch: false,
      captchaToken: null,
      emails: [],
    };
  },
  methods: {
    async sendUserInviation() {
      this.loader = true;
      this.emails.push(this.email);
      if (this.email1) {
        this.emails.push(this.email1);
      }
      if (this.email2) {
        this.emails.push(this.email2);
      }
      let emitData = {
        emails: this.emails,
        captchaToken: this.captchaToken,
      };
      // window.grecaptcha.reset();
      this.$emit("success", emitData);
    },
    openAgreementDialog(view) {
      this.termsConditionDialog = true;
      this.defaultView = view;
    },
    submit(response) {
      if (response) {
        this.verifyCaptch = true;
        this.captchaToken = response;
      }
    },
  },
  computed: {
    isDisabled() {
      if (this.$v.$invalid) {
        return true;
      } else {
        return false;
      }
    },
    isReadyToSend() {
      if (this.email) {
        return true;
      }
      return false;
    },
  },
};
</script>
