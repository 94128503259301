<template>
  <div class="signup-page" v-if="!isMobile">
    <div class="flex-direction window-height page-layout">
      <!-- <div @click="redirectToSite" class="mobile-view cursor-pointer">
        <img alt="logo" src="/static/images/signin/heycollab_logo_white.svg" />
      </div> -->
      <div
        class="left-side-view text-white"
        :class="stage > 4 && stage < 9 ? 'left-side-view-rmbg' : ''"
      >
        <img
          v-if="stage <= 1"
          class="image"
          src="/static/images/create-company/step1.jpg"
        />
        <img
          v-if="stage == 2"
          class="image"
          src="/static/images/create-company/step1.jpg"
        />
        <img
          v-if="stage == 3"
          class="image"
          src="/static/images/create-company/step3.jpg"
        />
        <img
          v-if="stage == 9"
          class="image"
          src="/static/images/create-company/step9.jpg"
        />
        <img
          v-if="stage == 4"
          class="image"
          src="/static/images/create-company/step4.jpg"
        />
        <img
          v-if="stage == 5"
          class="image"
          src="/static/images/create-company/step5.jpg"
        />
        <img
          v-if="stage == 6"
          class="image"
          src="/static/images/create-company/step6.jpg"
        />
        <img
          v-if="stage == 7"
          class="image"
          src="/static/images/create-company/step7.jpg"
        />
        <img
          v-if="stage == 8"
          class="image"
          style="width: 100%; object-fit: cover"
          src="/static/images/create-company/step3.jpg"
        />
        <!-- <div v-if="stage === 41" class="q-pl-xl left-side">
          <img
            @click="redirectToSite"
            alt="logo"
            class="three-dots cursor-pointer"
            src="/static/images/create-company/3-dots.svg"
          />
          <div class="title-taxt">
            <div class="big-text q-mt-lg" style="line-height: 1.2;">
              <div style="color:#006EE5">We're prepping up</div>
              <div style="color:#232C3B">your workspace</div>
            </div>
            <div class="row flex-no-wrap items-center q-mt-lg">
              <q-linear-progress
                v-if="loader"
                :value="signupProgress / 100"
                size="50px"
                style="color:#007AFF;border-radius:25px"
                rounded
              >
                <div
                  class="absolute-full subtask-progress-label q-ml-md"
                  style="font-size: 20px;font-weight: 700;color: #000;align-items: center;display: flex;"
                >
                {{ signupProgress }}%
                </div>
              </q-linear-progress>
            </div>
            <div class="small-text q-pr-xl q-mt-lg app-text-normal" style="color:#241D3B;font-weight: 500;font-size: 20px;">
              This should be quick
            </div>
          </div>
        </div> -->
        <div v-else class="image-content q-pl-xl">
          <!-- <img
            @click="redirectToSite"
            style="margin-top: 80px"
            alt="logo"
            class="cursor-pointer"
            src="/static/images/signin/heycollab_logo_white.svg"
          />
          <div style="margin-top: 110px">
            <div class="big-text">
              <div>Work.</div>
              <div>In harmony.</div>
            </div>
            <div
              v-if="stage == 6"
              class="small-text q-pr-xl q-mt-md app-text-normal"
            >
              Smart collaboration for teams big and small.
            </div>
            <div
              v-else-if="stage == 7"
              class="small-text q-pr-xl q-mt-md app-text-normal"
            >
              A new project coordination tool for teams.
            </div>
            <div v-else class="small-text q-pr-xl q-mt-md app-text-normal">
              Smart productivity for teams big and small.
            </div>
          </div>
          <div class="row bottom-content absolute">
            <div
              @click="openAgreementDialog('policy')"
              class="q-mr-md cursor-pointer app-text-normal"
            >
              Privacy Policy
            </div>
            <div
              @click="openAgreementDialog('terms')"
              class="cursor-pointer app-text-normal"
            >
              Terms of use
            </div>
          </div> -->
        </div>
      </div>
      <div class="right-side-view col-grow app-text-normal">
        <div class="column signup-container q-pa-md q-pl-lg">
          <div class="row justify-between items-center">
            <div class="flex">
              <img
                @click="redirectToSite"
                alt="logo"
                class="cursor-pointer"
                src="/static/images/logo/logo.svg"
              />
            </div>
            <div
              class="top-right-text signin-section"
              style="font-weight: 500; color: #000"
              v-if="stage <= 1"
            >
              Already a member?<span
                class="redirect-text q-ml-xs cursor-pointer"
                @click="$router.push({ name: 'SigninView' })"
                >Sign in</span
              >
            </div>
          </div>
          <div class="col-grow row justify-center child-items-center">
            <email-verification
              @success="handleSentCodeSuccess"
              v-if="stage <= 1"
            ></email-verification>
            <verify-code
              @success="handleVerifySuccess"
              v-if="stage === 2"
              :email="email"
            ></verify-code>
            <user-profile
              @next="handleProfileSuccess"
              v-if="stage === 3"
            ></user-profile>
            <basic-data
              @next="handleBasicDataSuccess"
              :submitLoader="loader"
              v-if="stage === 9"
            ></basic-data>
            <company-details
              @success="handleCompanySuccess"
              :submitLoader="loader"
              v-if="stage === 4"
            ></company-details>
            <!-- <company-creation-waiting
              @success="handleCompanySuccess"
              :submitLoader="loader"
              v-if="stage === 41"
            ></company-creation-waiting> -->
            <setup-project
              @success="handleWorkspaceSuccess"
              :submitWorkspaceLoader="loader"
              v-if="stage === 5"
            ></setup-project>
            <create-task
              @success="handleTaskSuccess"
              :submitTaskLoader="loader"
              v-if="stage === 6"
              :workspaceName="workspace_name"
            ></create-task>
            <invite-user
              @success="handleInvitationSuccess"
              :submitLoader="loader"
              v-if="stage === 7"
              :skipInvitation="skipInvitationStep"
            ></invite-user>
            <import-data
              @success="handleInvitationSuccess"
              :submitLoader="loader"
              v-if="stage === 8"
              :skipImportData="skipImportDataStep"
              :workspace="workspace"
              :company="company"
            ></import-data>
          </div>
          <!-- <div class="row bottom-content justify-center signin-section" style="font-size: 12px;">
            <div
              @click="openAgreementDialog('policy')"
              class="q-mr-md cursor-pointer app-text-normal"
            >
              Privacy Policy
            </div>
            <div
              @click="openAgreementDialog('terms')"
              class="cursor-pointer app-text-normal"
            >
              Terms of use
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <terms-condition-dialog
      v-model="termsConditionDialog"
      v-if="termsConditionDialog"
      :defaultView="defaultView"
    />
  </div>
  <div v-else>
    <mobile-singin-view> </mobile-singin-view>
  </div>
</template>

<script>
import mixpanel from "@/mixpanel";

import has from "lodash/has";
import { login } from "@/services/auth";

import TermsConditionDialog from "@/components/Dialogs/TermsConditionDialog";
import EmailVerification from "./SignupView/Step1";
import VerifyCode from "@/views/SignupView/Step2";
import UserProfile from "@/views/SignupView/Step3";
import CompanyDetails from "@/views/SignupView/Step4";
// import CompanyCreationWaiting from "@/views/SignupView/Step41";
import SetupProject from "@/views/SignupView/Step5";
import CreateTask from "@/views/SignupView/Step6";
import InviteUser from "@/views/SignupView/Step7";
import ImportData from "@/views/SignupView/Step8";
import BasicData from "@/views/SignupView/Step9";
import MobileSinginView from "@/views/MobileSigninView";
import {
  CreateCompanyMutation,
  CreateWorkspaceMutation,
  Workspaces2Query,
  InvitationWorkspaceQuery,
  CreateTasksMutation,
  CheckCompanyStatusMutation,
  PlanQuery,
  FeaturesQuery,
} from "@/gql";

import errorHandler from "@/utils/ErrorHandler";

import serviceProvider from "@/services/ServiceProvider";

export default {
  name: "SignupView",
  components: {
    TermsConditionDialog,
    EmailVerification,
    VerifyCode,
    UserProfile,
    CompanyDetails,
    MobileSinginView,
    SetupProject,
    CreateTask,
    InviteUser,
    ImportData,
    BasicData,
    // CompanyCreationWaiting,
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      stage: 1,
      defaultView: null,
      email: "",
      code: null,
      first: null,
      last: null,
      username: null,
      password: null,
      company_name: null,
      company_uri: null,
      company: {},
      questions: {
        usage: null,
        kind: null,
        strength: null,
      },
      loader: false,
      termsConditionDialog: false,
      isMobile: false,
      type: "public",
      workspace_name: null,
      task: [],
      workspace: null,
      teamSize: null,
      tellAboutUs: null,
      selectedDepartment: null,
    };
  },
  methods: {
    skipInvitationStep() {
      //this.stage = 8;
      this.$router.push({
        name: "BoardView",
        params: {
          company: this.company.uri,
          workspace: this.workspace.id,
        },
      });
    },
    skipImportDataStep() {
      this.$router.push({
        name: "BoardView",
        params: {
          company: this.company.uri,
          workspace: this.workspace.id,
        },
      });
    },
    checkMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    redirectToSite() {
      window.open("https://heycollab.com/");
    },
    openAgreementDialog(view) {
      this.termsConditionDialog = true;
      this.defaultView = view;
    },
    handleSentCodeSuccess(data) {
      this.email = data.email;
      this.stage = 2;
    },
    handleVerifySuccess(data) {
      this.code = data.code;
      this.stage = 3;
    },
    handleProfileSuccess(data) {
      this.first = data.first;
      this.last = data.last;
      this.username = data.username;
      this.password = data.password;
      this.stage = 9;
    },
    handleBasicDataSuccess(data) {
      this.teamSize = data.teamSize;
      this.tellAboutUs = data.tellAboutUs;
      this.selectedDepartment = data.selectedDepartment;
      this.stage = 4;
    },
    handleCompanySuccess(data) {
      this.loader = true;
      this.company_name = data.company_name;
      this.company_uri = data.company_uri;
      this.agreeToPolicy = data.agreeToPolicy;
      this.createCompany();
    },
    handleWorkspaceSuccess(data) {
      this.workspace_name = data.workspace_name;
      this.createWorkspace();
    },
    handleTaskSuccess(data) {
      let workspaceData = this.$api.getEntity("workspace", this.workspace.id);
      const board_id = workspaceData.boards[0].id;
      const card_id = workspaceData.boards[0].cards[0].id;
      this.task.push({
        user_id: workspaceData.owner.id,
        title: data.task,
        sequence: "2",
        board_id: board_id,
        card_id: card_id,
        details: null,
      });
      this.task.push({
        title: data.task1,
        sequence: "3",
        board_id: board_id,
        card_id: card_id,
        details: null,
        user_id: workspaceData.owner.id,
      });
      this.task.push({
        title: data.task2,
        sequence: "4",
        board_id: board_id,
        card_id: card_id,
        details: null,
        user_id: workspaceData.owner.id,
      });
      this.createTask();
    },
    handleInvitationSuccess(data) {
      if (data) {
        let workspaceData = [];
        workspaceData.push(this.workspace.id);
        serviceProvider
          .sendInvitation(
            data.emails,
            workspaceData,
            data.captchaToken,
            this.$api
          )
          .then((result) => {
            if (result) {
              this.$emit("success", {
                emails: data.emails,
                workspaces: workspaceData,
              });
              this.$router.push({
                name: "BoardView",
                params: {
                  company: this.company.uri,
                  workspace: this.workspace.id,
                },
              });
            }
          })
          .catch((error) => {
            errorHandler.getErrorMessage(
              error,
              this,
              this.$t("message.validation.invalidCouponCode")
            );
          });
        //this.stage = 8;
        this.$mixpanelEvent("signup-invite-team-7", {
          emails: data.emails,
          workspaces: workspaceData,
        });
        window.gtag("event", "signup-flow", {
          event_category: "signup-invite-team",
          event_label: this.email,
        });
      }
    },
    async createCompany() {
      let variables = {
        allow_occational_mail: true,
        first: this.first,
        last: this.last,
        email: this.email,
        username: this.username,
        company_name: this.company_name,
        company_uri: this.company_uri,
        password: this.password,
        code: this.code,
        questions: this.questions,
        toc: this.agreeToPolicy,
        team_size: this.teamSize,
        reason_for_used: this.selectedDepartment,
        tell_about_us: this.tellAboutUs,
      };

      const company = await this.$api.mutate({
        mutation: CreateCompanyMutation,
        variables,
      });

      if (company && !has(company, "error")) {
        this.company = company.data.createCompany;
        if (!this.checkMobile()) {
          this.singIn();
          this.isMobile = false;
        } else {
          this.isMobile = true;
        }
      } else {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Internal Server Error",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    async singIn() {
      let credential = {
        username: this.email,
        password: this.password,
        company: this.company.uri,
      };
      const result = await login(credential);

      if (!has(result, "error")) {
        const shareData = {
          companyUri: this.company.uri,
          companyName: this.company.name,
          companyId: this.company.id,
          accessToken: result.access_token,
          refreshToken: result.refresh_token,
        };
        this.$store.dispatch("addToCompanies", shareData);
        this.$store.dispatch("activeCompany", this.company.uri);

        const variables = {
          plan_id: this.company.subscription.plan_id || 1,
        };
        const response = await this.$api.query({
          query: PlanQuery,
          cacheKey: "PlanQuery",
          variables,
        });
        const allFeatures = await this.$api.query({
          query: FeaturesQuery,
          cacheKey: "FeaturesQuery",
        });
        this.$store.dispatch("subscriptionPlanData", response.data);
        this.$store.dispatch("allFeatureList", allFeatures);

        const checkCompany = await this.$api.mutate({
          mutation: CheckCompanyStatusMutation,
        });
        const status = {
          isActive:
            checkCompany.data.checkCompanyStatus.company_status === "active",
          subscriptionOver:
            checkCompany.data.checkCompanyStatus.subscription_over,
          graceHours: checkCompany.data.checkCompanyStatus.grace_hours,
          hasPaycard: checkCompany.data.checkCompanyStatus.hasPaycard,
        };
        this.$store.dispatch("companyStatus", status);

        this.loader = false;
        if (this.company) {
          const message = this.$t("message.company.create");
          this.$q.notify({
            classes: "success-notification",
            position: "top-right",
            message,
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
          this.createCompanyModel = false;
          this.stage = 5;
          // this.$router.push({
          //   name: "BoardView",
          //   params: {
          //     company: this.company.uri,
          //     workspace: 1,
          //   },
          // });
          this.$mixpanelEvent("signup-create-a-team-4", {
            companyUri: this.company.uri,
            companyName: this.company.name,
            companyId: this.company.id,
          });

          window.gtag("event", "signup-flow", {
            event_category: "signup-create-a-team-4",
            event_label: this.email,
          });

          mixpanel.identify(this.user.id);
          mixpanel.people.set({
            $company: this.company.uri,
            $email: this.user.email,
            $first_name: this.user.first,
            $last_name: this.user.last,
            $created: this.user.created_at,
          });
        }
      } else {
        this.loader = false;
      }
    },
    async createWorkspace() {
      this.loader = true;
      let variables = {
        title: this.workspace_name,
        type: this.type,
      };
      try {
        const response = await this.$api.mutate({
          mutation: CreateWorkspaceMutation,
          variables,
        });
        await this.$api.query({
          query: Workspaces2Query,
          cacheKey: "Workspaces2Query",
        });
        const newWorkspace = response.data.createWorkspace;
        this.workspace = newWorkspace;
        this.$api.query({
          query: InvitationWorkspaceQuery,
          cacheKey: "InvitationWorkspaceQuery",
        });

        //this.$emit("success");
        this.$eventBus.$emit("updateWorkspace", newWorkspace.id);
        this.loader = false;
        this.stage = 6;
        this.$mixpanelEvent("signup-setup-project-5", newWorkspace);
        window.gtag("event", "signup-flow", {
          event_category: "signup-setup-project-5",
          event_label: this.email,
        });
      } catch (error) {
        this.loader = false;

        this.errorMessage = {
          flag: true,
          text: errorHandler.getErrorMessage(
            error,
            this,
            "message.InvalidWorkspaceTitleError"
          ),
        };
      }
    },
    async createTask() {
      this.loader = true;
      let variables = {
        task: this.task,
        workspace_id:this.workspace.id,
      };
      try {
        let response = await this.$api.mutate({
          mutation: CreateTasksMutation,
          variables,
        });
        this.$mixpanelEvent("signup-3-tasks-6", response.data.createTask);
        window.gtag("event", "signup-flow", {
          event_category: "signup-create-tasks",
          event_label: this.email,
        });
        this.loader = false;
        this.$router.push({
          name: "BoardView",
          params: {
            company: this.company.uri,
            workspace: this.workspace.id,
          },
        });
      } catch (error) {
        this.loader = false;

        this.errorMessage = {
          flag: true,
          text: errorHandler.getErrorMessage(
            error,
            this,
            "message.InvalidWorkspaceTitleError"
          ),
        };
      }
    },
  },
};
</script>
