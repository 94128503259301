<template>
  <div>
    <form
      class="field-width details-field-width tell-us-about-you"
      v-on:submit.prevent="$v.$invalid ? null : submit()"
    >
      <div class="step-title" style="padding-bottom: 15px">
        Tell us a little about you
      </div>
      <div class="redio-group spacing-right col-grow q-mb-md">
        <div class="input-label" style="padding-bottom: 5px">
          How many people will you be working with?
        </div>
        <div class="people-selection">
          <q-radio
            class="q-mr-sm q-mt-sm"
            keep-color
            color="red"
            v-model="teamSize"
            val="Just me"
            label="Just me"
          />
          <q-radio
            class="q-mr-sm q-mt-sm"
            keep-color
            v-model="teamSize"
            val="2-5"
            label="2-5"
          />
          <q-radio
            class="q-mr-sm q-mt-sm"
            keep-color
            v-model="teamSize"
            val="6-10"
            label="6-10"
          />
          <q-radio
            class="q-mr-sm q-mt-sm"
            keep-color
            v-model="teamSize"
            val="11-25"
            label="11-25"
          />
          <q-radio class="q-mr-sm q-mt-sm" v-model="teamSize" val="26-50" label="26-50" />
          <q-radio
            class="q-mr-sm q-mt-sm"
            v-model="teamSize"
            val="51-200"
            label="51-200"
          />
          <q-radio
            class="q-mr-sm q-mt-sm"
            v-model="teamSize"
            val="201-500"
            label="201-500"
          />
          <q-radio class="q-mr-sm q-mt-sm" v-model="teamSize" val="500+" label="500+" />
          <q-radio
            class="q-mt-sm"
            v-model="teamSize"
            val="I don’t know"
            label="I don’t know"
          />
        </div>
        <template>
          <div class="text-negative">
            {{
              fieldErrors("teamSize").length > 0
                ? fieldErrors("teamSize")[0]
                : ""
            }}
          </div>
        </template>
      </div>
      <div class="spacing-right col-grow q-mb-md">
        <div class="input-label" style="padding-bottom: 5px">
          How do you want to start using Heycollab?
        </div>
        <div>
          <q-btn
            class="selected app-text-normal full-width"
            no-caps
            :icon-right="$icons.matKeyboardArrowDown"
            :label="selectedDepartment"
          >
            <q-menu content-class="app-text-normal overflow-auto" max-height="180px" v-model="typeMenu" fit>
              <q-list>
                <q-item
                  v-for="(data, i) in departmentData"
                  :key="i"
                  @click.stop="selectFromDepartmentDropdown(data)"
                  clickable
                >
                  <q-item-section>
                    {{ data }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
      <div class="row flex-wrap">
        <div class="about-us spacing-right col-grow q-mb-sm">
          <div class="input-label" style="padding-bottom: 5px">
            How did you hear about us?
          </div>
          <q-input
            type="text"
            ref="focusname"
            placeholder="Type your answer here"
            v-model="tellAboutUs"
            :error="fieldErrors('tellAboutUs').length > 0"
            @input="$v.tellAboutUs.$touch()"
            @blur="$v.tellAboutUs.$touch()"
            dense
            outlined
            bottom-slots
            no-error-icon
          >
            <template v-slot:error>
              <div class="text-negative">
                {{
                  fieldErrors("tellAboutUs").length > 0
                    ? fieldErrors("tellAboutUs")[0]
                    : ""
                }}
              </div>
            </template>
          </q-input>
        </div>
      </div>
      <q-btn
        type="submit"
        no-caps
        text-color="white"
        padding="0"
        label="Next"
        class="signin-btn-class"
        @click="submit()"
        :loading="loader"
        :disabled="isDisabled || loader"
      />
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
export default {
  name: "Step9",
  mixins: [validationMixin],
  validations: {
    tellAboutUs: { required },
    teamSize: { required },
    selectedDepartment: { required },
  },
  validationMessages: {
    tellAboutUs: {
      required: "Answer is required",
    },
    teamSize: {
      required: "message.validation.userprofile.last.required",
    },
    selectedDepartment: {
      required: "message.validation.username.required",
    },
  },
  components: {
    // VPopover,
  },
  data() {
    return {
      teamSize: null,
      departmentData: [
        "Support",
        "Personal Use",
        "HR & Recruiting",
        "Creative & Design",
        "Finance & Accounting",
        "IT",
        "Engineering & Product",
        "PMO - Project Management",
        "Sales & CRM",
        "Professional Services",
        "Marketing",
        "Operations",
        "Other",
      ],
      tellAboutUs: null,
      typeMenu: false,
      loader: null,
      selectedDepartment: "Support",
    };
  },
  mounted() {
    this.$track.page(`virtual/signup/3-user-profile`);
  },
  methods: {
    selectFromDepartmentDropdown(data) {
      this.typeMenu = false;
      this.selectedDepartment = data;
    },
    submit() {
      let emitData = {
        teamSize: this.teamSize,
        tellAboutUs: this.tellAboutUs,
        selectedDepartment: this.selectedDepartment,
      };
      //this.$mixpanelEvent('signup-account-creation-3',{first: this.first,last: this.last,username: this.username, distinct_id:'signup-flow'})
      this.$emit("next", emitData);
    },
  },
  computed: {
    isDisabled() {
      if (this.$v.$invalid) {
        return true;
      }
      return false;
    },
  },
};
</script>
