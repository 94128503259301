<template>
  <div>
    <form
      class="field-width"
      v-on:submit.prevent="$v.$invalid ? null : verify()"
    >
      <div class="step-title" style="margin-bottom: 0">Check your e-mail</div>
      <div class="check-email-instruction">
        We sent an e-mail with your code to
        <span class="email">{{ email }}</span>
      </div>
      <div class="input-label">Input your 5 digits confirmation code</div>
      <masked-input
        placeholder="000-00"
        mask="111-11"
        @input="code = arguments[1]"
        class="masked-box"
        :error-messages="fieldErrors('code')"
        @blur="$v.code.$touch()"
        type="tel"
      />
      <timmer
        :seconds="60"
        :onlyseconds="true"
        @timeover="startTimer = false"
        v-show="startTimer"
      >
        <span class="q-mr-xs" style="font-weight: 600"
          >Resend Confirmation Code in</span
        >
        <span slot="footer">seconds.</span>
      </timmer>
      <q-btn
        flat
        dense
        color="indigo-5"
        size="md"
        label="Resend Verification Code"
        no-caps
        class="q-mt-sm block"
        @click.native.stop="sendVerificationCode"
        v-if="!startTimer"
      />
      <p v-show="invalidCodeMessage" class="q-mt-sm text-negative">
        {{ invalidCodeMessage }}
      </p>
      <q-btn
        type="submit"
        no-caps
        text-color="white"
        padding="0"
        label="Next"
        class="signin-btn-class app-text-normal"
        @click="verify()"
        :loading="loader"
        :disabled="isDisabled || loader"
      />
    </form>
  </div>
</template>

<script>
import validationMixin from "@/mixins/validationMixin";
import { required } from "vuelidate/lib/validators";
import VerificationCodeMixin from "@/mixins/VerificationCodeMixin";
import errorHandler from "@/utils/ErrorHandler";

export default {
  name: "Step2",
  props: ["email"],
  mixins: [validationMixin, VerificationCodeMixin],
  validations: {
    code: { required },
  },
  validationMessages: {
    code: { required: "message.validation.verificationCode.required" },
  },
  mounted() {
    this.$track.page(`virtual/signup/2-verify-code`);
  },
  data() {
    return {
      loader: false,
      startTimer: true,
    };
  },
  methods: {
    async verify() {
      this.loader = true;
      this.invalidCodeMessage = null;

      let variables = {
        email: this.email,
        code: this.code,
      };

      try {
        const response = await this.checkVerificationMutation(variables);

        const isCorrectVerificationCode = response.data.checkVerificationCode;

        if (isCorrectVerificationCode) {
          this.$emit("success", variables);
          this.$mixpanelEvent("signup-code-2", {
            email: this.email,
            code: this.code,
            distinct_id: "signup-flow",
          });
          window.gtag("event", "signup-flow", {
            event_category: "signup-code-2",
            event_label: this.email,
          });
        } else {
          this.invalidCodeMessage = this.$t(
            "message.InvalidVerificationCodeError"
          );
        }
        this.loader = false;
      } catch (error) {
        this.invalidCodeMessage = errorHandler.getErrorMessage(
          error,
          this,
          "message.InvalidVerificationCodeError"
        );
        this.loader = false;
      }
    },
  },
  computed: {
    isDisabled() {
      if (this.$v.$invalid || (this.code && this.code.includes("_"))) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.masked-box {
  height: 60px;
  font-size: 3.5em;
  max-width: 335px;
  border: 1.5px solid #c1dcf3;
  border-radius: 6px;
  padding: 0px;
  letter-spacing: 35px;
  background: #fff;
}
.check-email-instruction {
  color: #59656e;
  font-size: 18px;
  margin-bottom: 35px;
  max-width: 335px;
  .email {
    font-weight: 500;
  }
}
</style>
