<template>
  <div>
    <form class="field-width details-field-width" v-on:submit.prevent>
      <div class="step-title">Give your team a name</div>
      <!-- <div class="input-label">Company Name</div>
      <q-input
        type="text"
        v-model.trim="company_name"
        @input="$v.company_name.$touch()"
        @blur="$v.company_name.$touch()"
        :error="fieldErrors('company_name').length > 0"
        style="width:188px"
        outlined
        dense
        bottom-slots
        no-error-icon
      >
        <template v-slot:error>
          <div class="text-negative">
            {{
              fieldErrors("company_name").length > 0
                ? fieldErrors("company_name")[0]
                : ""
            }}
          </div>
        </template>
      </q-input> -->
      <div class="q-mt-md">
        <!-- <div class="input-label">Team Name</div> -->
        <div class="team-name-instruction q-mb-md">
          Your team holds all your workspaces which keep your tasks, messages and files organized. 
        </div>
        <q-input
          type="text"
          v-model="company_uri"
          @input="handleCompanyUriInput"
          @blur="$v.company_uri.$touch()"
          :error="fieldErrors('company_uri').length > 0"
          outlined
          dense
          bottom-slots
          no-error-icon
          v-on:keyup="replaceSpace"
          maxlength="40"
          placeholder="Type your team name here"
        >
          <template v-slot:error>
            <div class="text-negative">
              {{
                fieldErrors("company_uri").length > 0
                  ? fieldErrors("company_uri")[0]
                  : ""
              }}
            </div>
          </template>
        </q-input>
        <div class="text-negative" v-if="uriTaken && !loader.checkUri">
          Team Name is already taken
        </div>
        <q-circular-progress
          indeterminate
          size="md"
          :thickness="0.2"
          class="q-mt-md"
          color="pink"
          v-if="loader.checkUri"
        />
      </div>
      <div class="cb-with-span q-mt-md">
        <q-checkbox size="md" v-model="privacyCheck" />
        <span class="signup-label-text q-ml-md q-mt-xs">
          I agree to heycollab's
          <span
            class="linkColor--text cursor-pointer"
            @click="openAgreementDialog('policy')"
            >Privacy Policy</span
          >
        </span>
      </div>
      <div class="cb-with-span">
        <q-checkbox size="md" v-model="termsCheck" />
        <span class="signup-label-text q-ml-md q-mt-xs">
          I am 18 years of age and have read and agree to heycollab's
          <span
            class="linkColor--text cursor-pointer"
            @click="openAgreementDialog('terms')"
            >Terms of Use</span
          >
        </span>
      </div>
      <div class="row flex-no-wrap items-center q-mt-md">
        <q-linear-progress
          v-if="submitLoader"
          :value="signupProgress / 100"
          size="20px"
          style="color:#15d89a;border-radius:8px"
          rounded
        >
          <div
            class="absolute-full subtask-progress-label q-ml-md"
            style="font-size: 13px;font-weight: 700;color: #000;"
          >
            {{ signupProgress }}%
          </div>
        </q-linear-progress>
      </div>
      <q-btn
        type="submit"
        text-color="white"
        padding="0"
        label="Next"
        class="signin-btn-class"
        style="margin-top:35px"
        @click="submit()"
        :loading="submitLoader"
        :disabled="disabled"
        no-caps
      />
    </form>
    <terms-condition-dialog
      v-model="termsConditionDialog"
      v-if="termsConditionDialog"
      :defaultView="defaultView"
    />
  </div>
</template>

<script>
import validationMixin from "@/mixins/validationMixin";
import { validCompanyUri } from "@/utils/validators";

import TermsConditionDialog from "@/components/Dialogs/TermsConditionDialog";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { CheckCompanyUriMutation } from "@/gql";
export default {
  name: "Step4",
  props: ["submitLoader"],
  mixins: [validationMixin],
  validations: {
    // company_name: {
    //   required,
    //   maxLength: maxLength(64),
    //   minLength: minLength(2),
    // },
    company_uri: {
      required,
      validCompanyUri,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    privacyCheck: { required },
    termsCheck: { required },
  },
  validationMessages: {
    // company_name: {
    //   required: "message.validation.company.name.required",
    //   maxLength: "message.validation.company.name.max",
    //   minLength: "message.validation.company.name.min",
    // },
    company_uri: {
      required: "message.validation.company.uri.required",
      maxLength: "message.validation.company.uri.max",
      minLength: "message.validation.company.uri.min",
      validCompanyUri: "message.validation.company.uri.validurl",
    },
    privacyCheck: {
      required: "message.validation.company.agreeToPolicy.required",
    },
    termsCheck: {
      required: "message.validation.company.agreeToPolicy.required",
    },
  },
  components: {
    TermsConditionDialog,
  },
  mounted() {
    this.$track.page(`virtual/signup/4-company-details`);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.bufferInterval);
  },
  data() {
    return {
      company_name: null,
      company_uri: null,
      uriTaken: false,
      privacyCheck: false,
      termsCheck: false,
      loader: {
        checkUri: false,
        submit: false,
      },
      termsConditionDialog: false,
      defaultView: null,
      agreementDialog: {
        dialogData: {
          title: null,
          desc: null,
        },
      },
      signupProgress: 0,
      totalProgress: 0,
    };
  },
  methods: {
    replaceSpace() {
      this.company_uri = this.company_uri.replace(/[\W_]/g, "-");
    },
    startProgressBar() {
      this.interval = setInterval(() => {
        if (this.signupProgress == 100) {
          clearInterval(this.interval);
          clearInterval(this.bufferInterval);
          return;
        }

        this.signupProgress = this.signupProgress + 1;
      }, 200);

      this.bufferInterval = setInterval(() => {
        if (this.totalProgress < 100) {
          this.totalProgress = Math.min(
            1,
            this.totalProgress + Math.random() * 2
          );
        }
      }, 250);
    },
    openAgreementDialog(view) {
      this.termsConditionDialog = true;
      this.defaultView = view;
    },
    handleCompanyUriInput() {
      this.uriTaken = false;
      this.$v.company_uri.$touch();
      this.company_uri = this.company_uri.toLowerCase();
      //this.company_uri = this.company_uri.replace(/\s/g, "");
      //this.company_uri = this.company_uri.trim();
    },
    async submit() {
      let emitData = {
        company_name: this.company_uri,
        company_uri: this.company_uri,
        agreeToPolicy: this.agreeToPolicy,
      };
      const result = await this.checkUriAvaibility();
      if (result) {
        this.startProgressBar();
        this.$emit("success", emitData);
      } else {
        this.uriSuggestions();
      }
    },
    async checkUriAvaibility() {
      this.uriTaken = false;
      this.loader.checkUri = true;

      let variables = {
        company_uri: this.company_uri,
      };
      try {
        const response = await this.$api.mutate({
          mutation: CheckCompanyUriMutation,
          variables,
        });

        this.loader.checkUri = false;

        if (response) return response.data.checkCompanyUri;
        return false;
      } catch (error) {
        this.loader.checkUri = false;
        return false;
      }
    },
    uriSuggestions() {
      this.uriTaken = true;
    },
  },
  computed: {
    agreeToPolicy() {
      return this.privacyCheck && this.termsCheck;
    },
    disabled() {
      return (
        this.$v.$invalid ||
        this.uriTaken ||
        this.loader.checkUri ||
        this.agreeToPolicy === false
      );
    },
  },
};
</script>
