<template>
  <div class="project-tasks">
    <form class="field-width" v-on:submit.prevent>
      <div class="step-title">
        Thanks! Let’s start with 3 tasks
      </div>
      <div class="step-title">
        for {{ workspaceName ? workspaceName : "project name" }}
      </div>
      <div class="join-label-text q-mt-sm">You’ll be able to add more later.</div>
      <div>
        <q-input
          type="text"
          ref="focusname"
          v-model="task"
          @blur="$v.task.$touch()"
          :error="fieldErrors('task').length > 0"
          dense
          outlined
          bottom-slots
          no-error-icon
          style="margin-bottom:16px;"
        >
          <template v-slot:prepend>
            <q-icon :name="$icons.matCheck" class="signup-check-icon" />
          </template>
          <template v-slot:error>
            <div class="text-negative">
              {{ fieldErrors("task").length > 0 ? fieldErrors("task")[0] : "" }}
            </div>
          </template>
        </q-input>
        <q-input
          type="text"
          v-model="task1"
          @blur="$v.task1.$touch()"
          :error="fieldErrors('task1').length > 0"
          dense
          outlined
          bottom-slots
          no-error-icon
          style="margin-bottom:16px;"
        >
          <template v-slot:prepend>
            <q-icon :name="$icons.matCheck" class="signup-check-icon" />
          </template>
          <template v-slot:error>
            <div class="text-negative">
              {{
                fieldErrors("task1").length > 0 ? fieldErrors("task1")[0] : ""
              }}
            </div>
          </template>
        </q-input>
        <q-input
          type="text"
          v-model="task2"
          @blur="$v.task2.$touch()"
          :error="fieldErrors('task2').length > 0"
          dense
          outlined
          bottom-slots
          no-error-icon
          style="margin-bottom:16px;"
        >
          <template v-slot:prepend>
            <q-icon :name="$icons.matCheck" class="signup-check-icon" />
          </template>
          <template v-slot:error>
            <div class="text-negative">
              {{
                fieldErrors("task2").length > 0 ? fieldErrors("task2")[0] : ""
              }}
            </div>
          </template>
        </q-input>
        <q-btn
          type="submit"
          no-caps
          text-color="white"
          padding="0"
          label="Let's go!"
          class="signin-btn-class"
          @click="submit()"
          :loading="submitTaskLoader"
          :disabled="$v.$invalid || loader"
        />
      </div>
    </form>
    <terms-condition-dialog
      v-model="termsConditionDialog"
      v-if="termsConditionDialog"
      :defaultView="defaultView"
    />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import TermsConditionDialog from "@/components/Dialogs/TermsConditionDialog";

export default {
  name: "Step6",
  props: ["submitTaskLoader", "workspaceName"],
  components: {
    TermsConditionDialog,
  },
  mixins: [validationMixin],
  validations: {
    task: { required },
    task1: { required },
    task2: { required },
  },
  validationMessages: {
    task: {
      required: "message.validation.task.required",
    },
    task1: {
      required: "message.validation.task.required",
    },
    task2: {
      required: "message.validation.task.required",
    },
  },
  mounted() {
    this.$track.page(`virtual/signup/6-create-tasks`);
    // this.$nextTick(() => {
    //   this.$refs.focusname.focus();
    // });
  },
  data() {
    return {
      loader: false,
      task: null,
      task1: null,
      task2: null,
      defaultView: null,
      termsConditionDialog: false,
    };
  },
  methods: {
    openAgreementDialog(view) {
      this.termsConditionDialog = true;
      this.defaultView = view;
    },
    async submit() {
      this.loader = true;
      let emitData = {
        task: this.task,
        task1: this.task1,
        task2: this.task2,
      };
      this.$emit("success", emitData);
    },
  },
};
</script>
